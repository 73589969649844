import {FC} from 'react'
import {ID} from '../../../../../../../_metronic/helpers'
import {Link} from 'react-router-dom'

type Props = {
  jobName: string
  id: ID
}

export const JobLinkCell: FC<Props> = ({jobName, id}) => {
  if (jobName && id) {
    return (
      <>
        <Link to={`/apps/job-management/job/${id}`}>{jobName}</Link>
      </>
    )
  } else {
    return <></>
  }
}
