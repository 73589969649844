import {FC, useEffect, useState} from 'react'
import {Stock} from '../stock-list/core/_models'
import {KTCard, KTSVG} from '../../../../../_metronic/helpers'
import {useListView} from '../../../../../_metronic/common/table/core/ListViewProvider'
import {useAuth} from '../../../auth'

type Props = {
  stock?: Stock
  isLoading: boolean
}

export const StockHeader: FC<Props> = ({stock, isLoading}) => {
  const [availableQuantity, setAvailableQuantity] = useState<number>(0)
  const {setItemIdForUpdate} = useListView()
  const {isSuperAdmin, isAdmin, isStockManager} = useAuth()
  useEffect(() => {
    const getAvailableQuantity = () => {
      const quantity = stock?.quantity ?? 0
      const available = stock?.available ?? 0
      return quantity - available
    }

    setAvailableQuantity(getAvailableQuantity())
  }, [stock])

  const openEditModal = () => {
    if (stock) {
      setItemIdForUpdate(stock.id)
    }
  }

  return (
    <>
      <KTCard className='mb-5'>
        <div>
          <div className='card-body pt-2 pb-1'>
            <div className='d-flex flex-wrap flex-sm-nowrap'>
              <div className='flex-grow-1'>
                <>
                  <div className='card-header border-0 pt-2'>
                    {/* begin::Card toolbar */}

                    <div className=''>
                      <div className='d-flex flex-column'>
                        <div className='d-flex align-items-center'>
                          <div className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>{stock?.name}</div>
                          <div className='text-gray-400 fw-semibold ps-3'>{stock?.description}</div>
                        </div>
                      </div>
                    </div>
                    <div className=''>
                      <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                        {(isSuperAdmin() || isAdmin() || isStockManager()) && (
                          <button type='button' className='btn btn-primary btn-sm' onClick={openEditModal}>
                            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                            Edit
                          </button>
                        )}
                      </div>
                    </div>
                    {/* end::Card toolbar */}
                  </div>

                  {/* <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center'>
                        <div className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>{stock?.name}</div>
                        <div className='text-gray-400 fw-semibold ps-3'>{stock?.description}</div>
                      </div>
                    </div>
                  </div> */}

                  <div className='d-flex flex-wrap'>
                    <div className='flex-equal me-5'>
                      <table className='table table-flush fw-semibold gy-1'>
                        <tbody>
                          {/* <tr>
                            <td className='text-muted min-w-125px w-125px'>Description</td>
                            <td className='text-gray-800'>{stock?.description}</td>
                          </tr> */}

                          <tr>
                            <td className='text-muted min-w-125px w-125px'>Quantity</td>
                            <td className='text-gray-800'>{stock?.quantity}</td>
                          </tr>

                          <tr>
                            <td className='text-muted min-w-125px w-125px'>Allocated</td>
                            <td className='text-gray-800'>{stock?.allocated}</td>
                          </tr>
                          <tr>
                            <td className='text-muted min-w-125px w-125px'>Available</td>
                            <td className='text-gray-800'>{availableQuantity} </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='flex-equal'>
                      <table className='table table-flush fw-semibold gy-1'>
                        <tbody>
                          <tr>
                            <td className='text-muted min-w-125px w-125px'>Unit Price</td>
                            <td className='text-gray-800'>R {stock?.unitPrice}</td>
                          </tr>

                          <tr>
                            <td className='text-muted min-w-125px w-125px'>Minimum Quantity</td>
                            <td className='text-gray-800'>{stock?.minimumQuantity}</td>
                          </tr>

                          <tr>
                            <td className='text-muted min-w-125px w-125px'>Bin</td>
                            <td className='text-gray-800'>{stock?.bin}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </KTCard>
    </>
  )
}
