import React, {FC, useEffect, useState} from 'react'
import {ID, KTSVG} from '../../../../../../../_metronic/helpers'
import {Stock} from '../../core/_models'

type Props = {
  id: ID
  stock: Stock
}

export const StockAvailableQuantityCell: FC<Props> = ({id, stock}) => {
  const [availableQuantity, setAvailableQuantity] = useState<number>((stock.quantity ?? 0) - (stock.allocated ?? 0))
  const [textColor, setTextColor] = useState<string>('text-success')
  const [icon, setIcon] = useState<string>('gen044.svg')

  useEffect(() => {
    setAvailableQuantity((stock.quantity ?? 0) - (stock.allocated ?? 0))
    if (stock.minimumQuantity) {
      if (availableQuantity === 0) {
        setTextColor('text-danger')
        setIcon('gen044.svg')
      } else if (availableQuantity < stock.minimumQuantity) {
        setTextColor('text-warning')
        setIcon('gen044.svg')
      } else {
        setTextColor('text-success')
        setIcon('gen037.svg')
      }
    }
  }, [stock, availableQuantity])

  return (
    <>
      <div className={`${textColor}`}>
        <KTSVG path={`/media/icons/duotune/general/${icon}`} className={`svg-icon-2 ${textColor}`} /> {availableQuantity}
      </div>

      {/* {stock.minimumQuantity ? (
        <>
          {availableQuantity < stock.minimumQuantity ? (
            <>
              {availableQuantity == 0 ? (
                <>
                  
                  </>
                  ) : (
                    <>
                    
              <div className='text-warning'>
                <KTSVG path='/media/icons/duotune/general/gen044.svg' className='svg-icon-2 text-warning' /> {availableQuantity}
              </div>

            </>
          ) : (
            <>
              <div className='text-success'>
                <KTSVG path='/media/icons/duotune/general/gen037.svg' className='svg-icon-2 text-success' /> {availableQuantity}
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div>NaN</div>
        </>
      )} */}

      {/* {inBatch ? (
        <div>
          <KTSVG path='/media/icons/duotune/general/gen037.svg' className='svg-icon-2' />
        </div>
      ) : (
        <div></div>
      )} */}
    </>
  )
}
