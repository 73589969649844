import React, {FC} from 'react'
import {StockListSearchComponent} from './StockListSearchComponent'
import {useListView} from '../../../../../../../_metronic/common/table/core/ListViewProvider'
import {StockListGrouping} from './StockListGrouping'
import {StockListToolbar} from './StockListToolbar'

export const StockListHeader: FC<{jobStockList?: boolean}> = ({jobStockList = false}) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <StockListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {!jobStockList && <>{selected.length > 0 ? <StockListGrouping /> : <StockListToolbar />}</>}

        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}
