import {FC} from 'react'
import {KTSVG} from '../../helpers'
import {useListView} from '../table/core/ListViewProvider'

type EditModalHeaderArgs = {
  title: string
}

const EditModalHeader: FC<EditModalHeaderArgs> = ({title}) => {
  const {setItemIdForUpdate, setAction} = useListView()

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>{title}</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => {
          setItemIdForUpdate(undefined)
          setAction(undefined)
        }}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {EditModalHeader}
