import {FC, useState} from 'react'
import {Stock} from '../core/_models'
import {useListView} from '../../../../../../_metronic/common/table/core/ListViewProvider'
import {useQueryResponse} from '../../../../../../_metronic/common/table/core/QueryResponseProvider'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {EditFormInput} from '../../../../../../_metronic/common/edit-modal/EditFormInput'
import {ListLoading} from '../../../../../../_metronic/common/table/loading/ListLoading'
import {addStock, allocateStock} from '../core/_requests'
import {useAuth} from '../../../../auth'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../../_metronic/helpers'
import {Job} from '../../../job-management/jobs-list/core/_models'
type Props = {
  stock: Stock
  job: Job
}

const allocateStockSchema = Yup.object().shape({
  quantityToAllocate: Yup.number().positive().required('Quantity to allocate is required'),
})

export const StockAllocateModalForm: FC<Props> = ({stock, job}) => {
  const {setAction} = useListView()
  const {refetch} = useQueryResponse()
  const [error, setError] = useState<string | null>(null)
  const {currentUser} = useAuth()
  const queryClient = useQueryClient()

  const [stockAllocateActionState] = useState<{quantityToAllocate: number}>({
    quantityToAllocate: 0,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setAction(undefined)
  }

  const formik = useFormik({
    initialValues: stockAllocateActionState,
    validationSchema: allocateStockSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        await allocateStock(currentUser, job.id, stock.id, values.quantityToAllocate)
        queryClient.refetchQueries(`${QUERIES.STOCK_EVENTS_LIST}-job-${job.id}`)
        queryClient.refetchQueries(`${QUERIES.JOB_DETAILS}-job-${job.id}`)
        setError(null)
        cancel(true)
      } catch (ex: any) {
        setError(ex.response.data.message)
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      {error && <div className='alert alert-danger'>{error}</div>}
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='row'>
          <div className='col-md-12'>
            <EditFormInput label='Quantity to Allocate' name='quantityToAllocate' fieldProps={formik.getFieldProps('quantityToAllocate')} touchedProp={formik.touched.quantityToAllocate} errorsProp={formik.errors.quantityToAllocate} isSubmitting={formik.isSubmitting} isLoading={false} type='number' />
          </div>
          <div className='col-md-12'></div>
        </div>

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button type='reset' onClick={() => cancel()} className='btn btn-light me-3' data-kt-users-modal-action='cancel' disabled={formik.isSubmitting}>
            Discard
          </button>

          <button type='submit' className='btn btn-primary' data-kt-users-modal-action='submit' disabled={formik.isSubmitting || !(formik.isValid && formik.dirty) || !formik.touched}>
            <span className='indicator-label'>Submit</span>
            {formik.isSubmitting && (
              <span className='indicator-progress'>
                Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {formik.isSubmitting && <ListLoading />}
    </>
  )
}
