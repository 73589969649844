import React, {FC, useState} from 'react'
import {Job} from '../jobs-list/core/_models'
import {KTCard, KTSVG, QUERIES} from '../../../../../_metronic/helpers'
import {useQueryClient} from 'react-query'
import {closeJob} from '../jobs-list/core/_requests'
import {useAuth} from '../../../auth'

type Props = {
  job?: Job
  isLoading: boolean
}

export const JobHeader: FC<Props> = ({job, isLoading}) => {
  const [isSubmitting, setSubmitting] = React.useState(false)
  const queryClient = useQueryClient()
  const {isSuperAdmin, isAdmin, isStockManager, currentUser} = useAuth()
  const [error, setError] = useState<string | null>(null)
  const close = async () => {
    if (job) {
      try {
        setSubmitting(true)
        await closeJob(currentUser, job.id)
        queryClient.refetchQueries(`${QUERIES.JOB_DETAILS}-job-${job.id}`)
      } catch (ex: any) {
        console.error(ex)
        setError(ex.response.data.message)
      } finally {
        setSubmitting(false)
      }
    } else {
      throw new Error('Job is undefined')
    }
  }

  return (
    <>
      <KTCard className='mb-5'>
        <div>
          <div className='d-flex flex-wrap flex-sm-nowrap'>
            <div className='flex-grow-1'>
              <>
                {error && <div className='alert alert-danger ms-10 me-10 mt-10'>{error}</div>}
                <div className='card-header border-0 pt-6 '>
                  {/* begin::Card toolbar */}

                  <div className='d-flex align-items-center position-relative my-1'>
                    {/* <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1 position-absolute ms-6' /> */}
                    <div className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>{job?.projectName} </div>
                    {/* <input type='text' data-kt-user-table-filter='search' className='form-control form-control-solid w-250px ps-14' placeholder='Search waybills' /> */}
                  </div>
                  <div className=''>
                    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                      {(isSuperAdmin() || isAdmin() || isStockManager()) && job?.jobStatus === 'ACTIVE' && (
                        <button onClick={() => close()} className='btn btn-danger btn-sm mt-1' disabled={isSubmitting || (job.allocatedCost ? job.allocatedCost > 0 : false)}>
                          {!isSubmitting && 'Close Job'}
                          {isSubmitting && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Closing Job <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                  {/* end::Card toolbar */}
                </div>

                <div className='card-body border-0 pt-6'>
                  <div className='d-flex flex-wrap'>
                    <div className='flex-equal me-5'>
                      <table className='table table-flush fw-semibold gy-1'>
                        <tbody>
                          <tr>
                            <td className='text-muted min-w-125px w-125px'>Date</td>
                            <td className='text-gray-800'>{job?.date}</td>
                          </tr>

                          <tr>
                            <td className='text-muted min-w-125px w-125px'>Gross Price</td>
                            <td className='text-gray-800'>R {job?.grossPrice?.toFixed(2)}</td>
                          </tr>
                          <tr>
                            <td className='text-muted min-w-125px w-125px'>Job Status</td>
                            <td className={` ${job?.jobStatus === 'COMPLETE' ? 'text-success' : 'text-warning'}`}>
                              <span className=''>{job?.jobStatus}</span>
                            </td>
                          </tr>

                          <tr>
                            <td className='text-muted min-w-125px w-125px'>Allocated Cost</td>
                            <td className='text-gray-800'>R {job?.allocatedCost ? (job?.allocatedCost).toFixed(2) : '-'}</td>
                          </tr>

                          <tr>
                            <td className='text-muted min-w-125px w-125px'>Issued Cost</td>
                            <td className='text-gray-800'>R {job?.issuedCost ? (job?.issuedCost).toFixed(2) : '-'}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='flex-equal'>
                      <table className='table table-flush fw-semibold gy-1'></table>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </KTCard>

      {/* <KTCard className='mb-5'>
        <div>
          <div className='card-body pt-2 pb-1'>
            <div className='d-flex flex-wrap flex-sm-nowrap'>
              <div className='flex-grow-1'>
                <>
                  <div className='border-0 pt-2'>
                    <div className=''>
                      <div className='d-flex flex-column'>
                        <div className='d-flex align-items-center'>
                          <div className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>{job?.projectName}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex flex-wrap'>
                    <div className='flex-equal me-5'>
                      <table className='table table-flush fw-semibold gy-1'>
                        <tbody>
                          <tr>
                            <td className='text-muted min-w-125px w-125px'>Date</td>
                            <td className='text-gray-800'>{job?.date}</td>
                          </tr>

                          <tr>
                            <td className='text-muted min-w-125px w-125px'>Gross Price</td>
                            <td className='text-gray-800'>{job?.grossPrice}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='flex-equal'>
                      <table className='table table-flush fw-semibold gy-1'></table>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </KTCard> */}
    </>
  )
}
