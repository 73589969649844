/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {EACTION_TYPE, ID, KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../../../../../../_metronic/common/table/core/ListViewProvider'
import {Stock} from '../../core/_models'

type Props = {
  id: ID
  stock: Stock
}

const StockActionsCell: FC<Props> = ({id, stock}) => {
  const {setItemIdForUpdate, setAction} = useListView()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const openAddModal = () => {
    setAction({type: EACTION_TYPE.ACTION_ADD_STOCK, id: id, payload: stock})
  }

  const openAdjustModal = () => {
    setAction({type: EACTION_TYPE.ACTION_ADJUST_STOCK, id: id, payload: stock})
  }
  // const deleteItem = useMutation(() => deleteUser(currentUser, id), {
  //   // 💡 response of the mutation is passed to onSuccess
  //   onSuccess: () => {
  //     // ✅ update detail view directly
  //     queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
  //   },
  // })

  return (
    <>
      <a href='#' className='btn btn-light btn-active-light-primary btn-sm' data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end'>
        Actions
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4' data-kt-menu='true'>
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Edit
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            // onClick={async () => await deleteItem.mutateAsync()}
          >
            Delete
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openAddModal}>
            Add Stock
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        {/* <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openAdjustModal}>
            Adjust Stock
          </a>
        </div> */}
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {StockActionsCell}
