import {FC} from 'react'
import {ID} from '../../../../../../../_metronic/helpers'
import {Link} from 'react-router-dom'

type Props = {
  projectName: string
  id: ID
}

export const JobLinkCell: FC<Props> = ({projectName, id}) => {
  return (
    <>
      <Link to={`/apps/job-management/job/${id}`}>{projectName}</Link>
    </>
  )
}
