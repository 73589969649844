import {ID} from '../../../../../../_metronic/helpers'
import {UserModel} from '../../../../auth'
import {StockQueryResponse} from '../../../stock-management/stock-list/core/_models'
import {create, get, issue, list, update, close} from './JobHelpers.amplify'
import {Job} from './_models'

const getJobById = (id: ID, currentUser: UserModel | undefined): Promise<Job | undefined> => {
  if (currentUser && id) {
    return get(id, currentUser).then((result) => {
      return result.data
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

const createJob = (currentUser: UserModel | undefined, job: Job): Promise<Job | undefined> => {
  if (currentUser) {
    return create(currentUser, job).then((result) => {
      return result.data
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

const issueStockForJob = (currentUser: UserModel | undefined, id: ID): Promise<Job | undefined> => {
  if (currentUser && id) {
    return issue(currentUser, id).then((result) => {
      return result.data
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

const closeJob = (currentUser: UserModel | undefined, id: ID): Promise<Job | undefined> => {
  if (currentUser && id) {
    return close(currentUser, id).then((result) => {
      return result.data
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

const updateJob = (currentUser: UserModel | undefined, stock: Job): Promise<Job | undefined> => {
  if (currentUser) {
    return update(currentUser, stock).then((result) => {
      return result.data
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

const listJobs = async (query: string, currentUser?: UserModel, pageParam?: any): Promise<StockQueryResponse> => {
  if (currentUser) {
    if (pageParam) {
      query += `&last_evaluated_key_pk=${encodeURIComponent(pageParam.pk)}&last_evaluated_key_sk=${encodeURIComponent(pageParam.sk)}&last_evaluated_key_gsi1pk=${encodeURIComponent(pageParam.gsi1pk)}&last_evaluated_key_gsi1sk=${encodeURIComponent(pageParam.gsi1sk)}`
    }

    return list(currentUser, query).then((result) => {
      return result
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

export {listJobs, getJobById, createJob, updateJob, issueStockForJob, closeJob}
