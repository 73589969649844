import {FC} from 'react'
import {Stock} from '../stock-list/core/_models'
import {EACTION_TYPE, KTCard, KTSVG} from '../../../../../_metronic/helpers'
import {StockEventsListWrapper} from '../stock-events/StockEventsList'
import {useListView} from '../../../../../_metronic/common/table/core/ListViewProvider'
import {useAuth} from '../../../auth'

type Props = {
  stock?: Stock
  isLoading: boolean
}

export const StockBody: FC<Props> = ({stock, isLoading}) => {
  const {setAction} = useListView()
  const {isSuperAdmin, isAdmin, isStockManager} = useAuth()
  const openAddModal = () => {
    if (stock) {
      setAction({type: EACTION_TYPE.ACTION_ADD_STOCK, id: stock.id, payload: stock})
    }
  }

  return (
    <>
      <KTCard className='mb-5'>
        <div>
          <div className=''>
            <div className='flex-grow-1'>
              <>
                <div className='card-header border-0 pt-6'>
                  {/* begin::Card toolbar */}

                  <div className='d-flex align-items-center position-relative my-1'>
                    {/* <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1 position-absolute ms-6' /> */}
                    <div className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>Stock Events</div>
                    {/* <input type='text' data-kt-user-table-filter='search' className='form-control form-control-solid w-250px ps-14' placeholder='Search waybills' /> */}
                  </div>
                  <div className=''>
                    {/* <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                      {(isSuperAdmin() || isAdmin() || isStockManager()) && (
                        <button type='button' className='btn btn-primary btn-sm' onClick={openAddModal}>
                          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                          Add New Stock Item
                        </button>
                      )}
                    </div> */}
                  </div>
                  {/* end::Card toolbar */}
                </div>

                <div className=''>
                  <div className='flex-equal me-5'>
                    <StockEventsListWrapper stock={stock} />
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </KTCard>
    </>
  )
}
