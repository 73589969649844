import {API} from 'aws-amplify'
import {UserModel} from '../../../../auth'
import {Stock} from './_models'

export const list = async (currentUser: UserModel, query: string) => {
  return API.get('app_base', `/organization/${currentUser.currentOrganization}/stock?${query}`, {})
}

export const get = async (id: string, currentUser: UserModel) => {
  const encodedUrl = encodeURIComponent(`/organization/${currentUser.currentOrganization}/stock/${id}`)
  return API.get('app_base', encodedUrl, {})
}

export const create = async (currentUser: UserModel, stock: Stock) => {
  const encodedUrl = encodeURIComponent(`/organization/${currentUser.currentOrganization}/stock`)
  return API.post('app_base', encodedUrl, {body: stock})
}

export const add = async (currentUser: UserModel, id: string, quantityToAdd: number, unitPrice: number) => {
  const encodedUrl = encodeURIComponent(`/organization/${currentUser.currentOrganization}/stock/${id}/add`)
  return API.post('app_base', encodedUrl, {body: {quantityToAdd, unitPrice}})
}

export const allocate = async (currentUser: UserModel, jobId: string, stockId: string, quantityToAllocate: number) => {
  const encodedUrl = encodeURIComponent(`/organization/${currentUser.currentOrganization}/job/${jobId}/stock/${stockId}/allocate`)
  return API.post('app_base', encodedUrl, {body: {quantityToAllocate}})
}

export const update = async (currentUser: UserModel, stock: Stock) => {
  const encodedUrl = encodeURIComponent(`/organization/${currentUser.currentOrganization}/stock/${stock.id}`)
  return API.put('app_base', encodedUrl, {body: stock})
}

export const report = async (currentUser: UserModel) => {
  const url = `/organization/${currentUser.currentOrganization}/stock/report`
  return API.post('app_base', url, {})
}
