import React, {useState} from 'react'
import {refreshArb} from '../../core/_requests'
import {useAuth} from '../../../../../auth'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../../../_metronic/helpers'
import {useQueryResponse} from '../../../../../../../_metronic/common/table/core/QueryResponseProvider'

export const ArbListHeader = () => {
  const [isSubmitting, setSubmitting] = useState(false)
  const queryClient = useQueryClient()
  const {currentUser} = useAuth()
  const {query} = useQueryResponse()
  const refresh = async () => {
    try {
      setSubmitting(true)
      await refreshArb(currentUser)
      await queryClient.refetchQueries(`${QUERIES.ARB_LIST}-${query}`)
    } catch (ex: any) {
      console.error(ex)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div className='card-header border-0 pt-6'>
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        <button type='button' className='btn btn-sm btn-primary' onClick={refresh} disabled={isSubmitting}>
          {!isSubmitting && 'Refresh'}
          {isSubmitting && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Refreshing <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}
