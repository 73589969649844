import {FC, useState} from 'react'
import {Stock} from '../core/_models'
import {useListView} from '../../../../../../_metronic/common/table/core/ListViewProvider'
import {useQueryResponse} from '../../../../../../_metronic/common/table/core/QueryResponseProvider'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {EditFormInput} from '../../../../../../_metronic/common/edit-modal/EditFormInput'
import {ListLoading} from '../../../../../../_metronic/common/table/loading/ListLoading'
import {addStock} from '../core/_requests'
import {useAuth} from '../../../../auth'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../../_metronic/helpers'
type Props = {
  stock: Stock
}

const editStockSchema = Yup.object().shape({
  quantityToAdd: Yup.number().positive().required('Quantity to add is required'),
  unitPrice: Yup.number().positive('Must be a positive number').required('Unit price is required'),
})

export const StockAddModalForm: FC<Props> = ({stock}) => {
  const {setAction} = useListView()
  const {refetch} = useQueryResponse()
  const [error, setError] = useState<string | null>(null)
  const {currentUser} = useAuth()
  const queryClient = useQueryClient()
  const [stockActionState] = useState<{quantityToAdd: number; unitPrice: number}>({
    quantityToAdd: 0,
    unitPrice: 0,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setAction(undefined)
  }

  const formik = useFormik({
    initialValues: stockActionState,
    validationSchema: editStockSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        await addStock(currentUser, stock.id, values.quantityToAdd, values.unitPrice)
        queryClient.refetchQueries(`${QUERIES.STOCK_DETAILS}-stock-${stock.id}`)
        setError(null)
        cancel(true)
      } catch (ex: any) {
        console.error(ex)
        setError(ex.response.data.message)
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      {error && <div className='alert alert-danger'>{error}</div>}
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='row'>
          <div className='col-md-12'>
            <EditFormInput label='Quantity to Add' name='quantityToAdd' fieldProps={formik.getFieldProps('quantityToAdd')} touchedProp={formik.touched.quantityToAdd} errorsProp={formik.errors.quantityToAdd} isSubmitting={formik.isSubmitting} isLoading={false} type='number' />

            <EditFormInput label='Unit Price' name='unitPrice' fieldProps={formik.getFieldProps('unitPrice')} touchedProp={formik.touched.unitPrice} errorsProp={formik.errors.unitPrice} isSubmitting={formik.isSubmitting} isLoading={false} type='number' />
          </div>
          <div className='col-md-12'></div>
        </div>

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button type='reset' onClick={() => cancel()} className='btn btn-light me-3' data-kt-users-modal-action='cancel' disabled={formik.isSubmitting}>
            Discard
          </button>

          <button type='submit' className='btn btn-primary' data-kt-users-modal-action='submit' disabled={formik.isSubmitting || !(formik.isValid && formik.dirty) || !formik.touched}>
            <span className='indicator-label'>Submit</span>
            {formik.isSubmitting && (
              <span className='indicator-progress'>
                Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {formik.isSubmitting && <ListLoading />}
    </>
  )
}
