import {API} from 'aws-amplify'
import {UserModel} from '../../../../auth'
import {ID} from '../../../../../../_metronic/helpers'

export const stockEvents = async (currentUser: UserModel, stockId: ID) => {
  return API.get('app_base', `/organization/${currentUser.currentOrganization}/stock/${stockId}/events`, {})
}

export const stockEventsForJob = async (currentUser: UserModel, jobId: ID) => {
  return API.get('app_base', `/organization/${currentUser.currentOrganization}/job/${jobId}/events`, {})
}

export const deleteAllocate = async (currentUser: UserModel, stockEventId: string) => {
  const url = `/organization/${currentUser.currentOrganization}/stock-event/${stockEventId}/delete`
  return API.post('app_base', url, {body: {}})
}
