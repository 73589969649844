import React, {FC} from 'react'
import {Action, EACTION_TYPE} from '../../../../../../_metronic/helpers'
import {EditModal} from '../../../../../../_metronic/common/edit-modal/EditModal'
import {StockAddModalForm} from './StockAddModalForm'
import {StockAllocateModalForm} from './StockAllocateModalForm'
type Props = {
  action: Action
}

export const StockActionsModalWrapper: FC<Props> = ({action}) => {
  if (action?.type === EACTION_TYPE.ACTION_ADD_STOCK) {
    return (
      <EditModal title={`Add Stock - ${action.payload.name}`}>
        <StockAddModalForm stock={action.payload} />
      </EditModal>
    )
  }

  if (action?.type === EACTION_TYPE.ACTION_ALLOCATE_STOCK) {
    return (
      <EditModal title={`Allocate Stock - ${action.payload.stock.name}`}>
        <StockAllocateModalForm stock={action.payload.stock} job={action.payload.job} />
      </EditModal>
    )
  }

  if (action?.type === EACTION_TYPE.ACTION_ADJUST_STOCK) {
    return <EditModal title={'ACTION_ADJUST_STOCK'}></EditModal>
  }
  return null
}
