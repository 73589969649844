import { Route, Routes, Outlet, Navigate } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import { ArbListWrapper } from "./arb-list/ArbList";

const arbBreadcrumbs: Array<PageLink> = [
    {
        title: "Arb Management",
        path: "/apps/arb-management",
        isSeparator: false,
        isActive: false,
    },
];

const ArbPage = () => {
    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path="arb"
                    element={
                        <>
                            <PageTitle breadcrumbs={arbBreadcrumbs}>Arb list</PageTitle>
                            <ArbListWrapper />
                        </>
                    }
                />
            </Route>
            <Route index element={<Navigate to="/apps/arb-management/arb" />} />
        </Routes>
    );
};

export default ArbPage;
