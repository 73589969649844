// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserActionsCell} from './UserActionsCell'
import {Arb, User} from '../../core/_models'
import {CustomHeader} from '../../../../../../../_metronic/common/table/columns/CustomHeader'
import {SelectionCell} from '../../../../../../../_metronic/common/table/columns/SelectionCell'
import {SelectionHeader} from '../../../../../../../_metronic/common/table/columns/SelectionHeader'
const arbColumns: ReadonlyArray<Column<Arb>> = [
  {
    Header: (props) => <CustomHeader tableProps={props} title='Date' className='min-w-125px' />,
    accessor: 'sk',
    Cell: (props) => <div> {new Date(Number(props.value?.substring(4, 25))).toLocaleDateString()} </div>,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Time' className='min-w-125px' />,
    accessor: 'sk',
    id: 'time',
    Cell: (props) => <div> {new Date(Number(props.value?.substring(4, 25))).toLocaleTimeString()} </div>,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Valr Arb' className='min-w-125px' />,
    accessor: 'valrArb.arb',
    Cell: ({...props}) => <ArbColumn arb={props.data[props.row.index].valrArb} otherArb={props.data[props.row.index].lunoArb} props={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Luno Arb' className='min-w-125px' />,
    accessor: 'lunoArb.arb',
    Cell: ({...props}) => <ArbColumn arb={props.data[props.row.index].lunoArb} otherArb={props.data[props.row.index].valrArb} props={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Valr' className='min-w-125px' />,
    accessor: 'valrArb.valr',
    Cell: (props) => <div> R {Number(props.value)?.toFixed(2)} </div>,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Luno' className='min-w-125px' />,
    accessor: 'lunoArb.luno',
    Cell: (props) => <div> R {Number(props.value)?.toFixed(2)} </div>,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Diff%' className='min-w-125px' />,
    id: 'diff',
    Cell: ({...props}) => <DiffColumn lunoArb={props.data[props.row.index].lunoArb} valrArb={props.data[props.row.index].valrArb} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Kraken' className='min-w-125px' />,
    accessor: 'lunoArb.kraken',
    Cell: (props) => <div> R {props.value?.toFixed(2)} </div>,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='EUR/ZAR' className='min-w-125px' />,
    accessor: 'fx.price',
    Cell: (props) => <div> R {props.value?.toFixed(4)} </div>,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='BTC EUR' className='min-w-125px' />,
    accessor: 'kraken.bid',
    Cell: (props) => <div> $ {Number(props.value)?.toFixed(2)} </div>,
  },
]

export {arbColumns}

type ArbColumnProps = {
  props: any
  arb: any
  otherArb: any
}

type DiffColumnProps = {
  luno: any
  valr: any
}

export const DiffColumn: FC<DiffColumnProps> = ({lunoArb, valrArb}) => {
  const getDiff = () => {
    if (lunoArb && valrArb) {
      return Math.abs(((lunoArb.luno - valrArb.valr) / valrArb.valr) * 100).toFixed(2) + '%'
    } else {
      return 'NaN'
    }
  }
  return <div>{getDiff()}</div>
  // return <div>{JSON.stringify(lunoArb)}</div>
}

const ArbColumn: FC<ArbColumnProps> = ({arb, otherArb, props}) => {
  const getArb = () => {
    if (arb) {
      return arb.arb
    } else if (props.arb) {
      return props.arb.arb
    } else {
      return 'NaN'
    }
  }

  const getClass = () => {
    if (arb) {
      if (arb.arb > otherArb.arb) {
        return 'text-success'
      } else {
        return 'text-danger'
      }
    } else if (props.arb) {
      return 'text-warning'
    } else {
      return 'text-warning'
    }
  }

  return <div className={getClass()}>{getArb()}</div>
}
