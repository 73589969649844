import clsx from 'clsx'

export const EditFormInput = ({label, name, fieldProps, touchedProp, errorsProp, isSubmitting, isLoading, showLabel = true, isRequired = true, type = 'text', disabled = false}: any) => {
  return (
    <>
      {/* begin::Input group */}
      <div className='fv-row mb-2'>
        {/* begin::Label */}
        {showLabel && <label className={clsx('fw-bold fs-7 mb-2', isRequired ? 'required' : '')}>{label}</label>}
        {/* end::Label */}

        {/* begin::Input */}
        <input
          placeholder={label}
          {...fieldProps}
          type={type}
          name={name}
          className={clsx(
            'form-control form-control-solid mb-3 mb-lg-0 fs-7',
            {'is-invalid': touchedProp && errorsProp},
            {
              'is-valid': touchedProp && !errorsProp,
            }
          )}
          autoComplete='off'
          disabled={isSubmitting || isLoading || disabled}
          style={{textTransform: 'uppercase'}}
        />
        {touchedProp && errorsProp && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{errorsProp}</span>
            </div>
          </div>
        )}
        {/* end::Input */}
      </div>
      {/* end::Input group */}
    </>
  )
}
