import React, {FC, useState} from 'react'
import {Stock} from '../core/_models'
import * as Yup from 'yup'
import {useListView} from '../../../../../../_metronic/common/table/core/ListViewProvider'
import {useQueryResponse} from '../../../../../../_metronic/common/table/core/QueryResponseProvider'
import {useAuth} from '../../../../auth'
import {useFormik} from 'formik'
import {QUERIES, isNotEmpty} from '../../../../../../_metronic/helpers'
import {createStock, updateStock} from '../core/_requests'
import {EditFormInput} from '../../../../../../_metronic/common/edit-modal/EditFormInput'
import {ListLoading} from '../../../../../../_metronic/common/table/loading/ListLoading'
import {useQueryClient} from 'react-query'

type Props = {
  isStockLoading: boolean
  stock: Stock
  isEdit: boolean
}

const editStockSchema = Yup.object().shape({
  name: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Name is required'),
  quantity: Yup.number().positive().required('Quantity is required'),
  unitPrice: Yup.number().positive('Must be a positive number').required('Required'),
  minimumQuantity: Yup.number().positive().required('Minimum Quantity is required'),
})
export const StockEditModalForm: FC<Props> = ({isStockLoading, stock, isEdit}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const {currentUser} = useAuth()
  const queryClient = useQueryClient()
  const [error, setError] = useState<string | null>(null)

  const [stockForEdit] = useState<Stock>({
    ...stock,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: stockForEdit,
    validationSchema: editStockSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateStock(currentUser, values)
          queryClient.refetchQueries(`${QUERIES.STOCK_DETAILS}-stock-${stock.id}`)
        } else {
          await createStock(currentUser, values)
        }
        setError(null)
        cancel(true)
      } catch (ex: any) {
        console.error(ex)
        setError(ex.response.data.message)
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      {error && <div className='alert alert-danger'>{error}</div>}
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='row'>
          <div className='col-md-12'>
            <EditFormInput label='Name' name='name' fieldProps={formik.getFieldProps('name')} touchedProp={formik.touched.name} errorsProp={formik.errors.name} isSubmitting={formik.isSubmitting} isLoading={isStockLoading} disabled={isEdit} />

            <EditFormInput label='Description' name='description' fieldProps={formik.getFieldProps('description')} touchedProp={formik.touched.description} errorsProp={formik.errors.description} isSubmitting={formik.isSubmitting} isLoading={isStockLoading} isRequired={false} />

            <EditFormInput label='Bin' name='bin' fieldProps={formik.getFieldProps('bin')} touchedProp={formik.touched.bin} errorsProp={formik.errors.bin} isSubmitting={formik.isSubmitting} isLoading={isStockLoading} isRequired={false} />

            <EditFormInput label='Quantity' name='quantity' fieldProps={formik.getFieldProps('quantity')} touchedProp={formik.touched.quantity} errorsProp={formik.errors.quantity} isSubmitting={formik.isSubmitting} isLoading={isStockLoading} type='number' disabled={isEdit} />

            <EditFormInput label='Minimum Quantity' name='minimumQuantity' fieldProps={formik.getFieldProps('minimumQuantity')} touchedProp={formik.touched.minimumQuantity} errorsProp={formik.errors.minimumQuantity} isSubmitting={formik.isSubmitting} isLoading={isStockLoading} type='number' />

            <EditFormInput label='Unit Price' name='unitPrice' fieldProps={formik.getFieldProps('unitPrice')} touchedProp={formik.touched.unitPrice} errorsProp={formik.errors.unitPrice} isSubmitting={formik.isSubmitting} isLoading={isStockLoading} type='number' disabled={isEdit} />
          </div>
          <div className='col-md-12'></div>
        </div>

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button type='reset' onClick={() => cancel()} className='btn btn-light me-3' data-kt-users-modal-action='cancel' disabled={formik.isSubmitting || isStockLoading}>
            Discard
          </button>

          <button type='submit' className='btn btn-primary' data-kt-users-modal-action='submit' disabled={isStockLoading || formik.isSubmitting || !(formik.isValid && formik.dirty) || !formik.touched}>
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isStockLoading) && (
              <span className='indicator-progress'>
                Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isStockLoading) && <ListLoading />}
    </>
  )
}
