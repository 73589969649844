import {useEffect} from 'react'
import {KTSVG, initialQueryState, useDebounce} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../../../../../../_metronic/common/table/core/QueryRequestProvider'

export const StockListSearchComponent = () => {
  const {updateState, searchTerm, setSearchTerm} = useQueryRequest()
  const debouncedSearchTerm = useDebounce(searchTerm.toUpperCase(), 500)

  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        updateState({search: debouncedSearchTerm, ...initialQueryState})
      }
    },
    [debouncedSearchTerm, searchTerm, updateState] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )

  return (
    <>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'>
        <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1 position-absolute ms-6' />

        <input type='text' data-kt-user-table-filter='search' className='form-control form-control-solid w-250px ps-14' placeholder='Search Stock' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
      </div>
      {/* end::Search */}
    </>
  )
}
