import React from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../../../../../../_metronic/common/table/core/ListViewProvider'
import {useAuth} from '../../../../../auth'

export const JobListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const {isSuperAdmin, isAdmin, isStockClerk} = useAuth()
  const openJobModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <>
      {(isSuperAdmin() || isAdmin() || isStockClerk()) && (
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
          {/* begin::Add user */}
          <button type='button' className='btn btn-sm  btn-primary' onClick={openJobModal}>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Add Job
          </button>
          {/* end::Add user */}
        </div>
      )}
    </>
  )
}
