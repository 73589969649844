import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { MenuTestPage } from "../pages/MenuTestPage";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import BuilderPageWrapper from "../pages/layout-builder/BuilderPageWrapper";
import CompaniesPage from "../modules/apps/company-management/CompaniesPage";
import WaybillsPage from "../modules/apps/waybill-management/WaybillsPage";
import SitesPage from "../modules/apps/site-management/SitesPage";
import { BatchProvider } from "../modules/batch/core/BatchProvider";
import JobsPage from "../modules/apps/job-management/JobsPage";
import StockPage from "../modules/apps/stock-management/StockPage";
import ArbPage from "../modules/apps/arb-management/ArbPage";

const PrivateRoutes = () => {
    const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
    const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
    const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
    const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
    const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));
    const UsersPage = lazy(() => import("../modules/apps/user-management/UsersPage"));

    return (
        <Routes>
            <Route element={<MasterLayout />}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route path="auth/*" element={<Navigate to="/apps/arb-management" />} />
                {/* Pages */}
                <Route path="dashboard" element={<DashboardWrapper />} />
                <Route path="builder" element={<BuilderPageWrapper />} />
                <Route path="menu-test" element={<MenuTestPage />} />
                {/* Lazy Modules */}

                <Route
                    path="apps/user-management/*"
                    element={
                        <SuspensedView>
                            <UsersPage />
                        </SuspensedView>
                    }
                />

                <Route
                    path="apps/job-management/*"
                    element={
                        <SuspensedView>
                            <JobsPage />
                        </SuspensedView>
                    }
                />

                <Route
                    path="apps/stock-management/*"
                    element={
                        <SuspensedView>
                            <StockPage />
                        </SuspensedView>
                    }
                />

                <Route
                    path="apps/arb-management/*"
                    element={
                        <SuspensedView>
                            <ArbPage />
                        </SuspensedView>
                    }
                />

                {/* Page Not Found */}
                <Route path="*" element={<Navigate to="/error/404" />} />
            </Route>
        </Routes>
    );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
    const baseColor = getCSSVariableValue("--kt-primary");
    TopBarProgress.config({
        barColors: {
            "0": baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    });
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
