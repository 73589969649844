/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {EACTION_TYPE, ID, KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../../../../../../_metronic/common/table/core/ListViewProvider'
import {StockEvent} from '../../core/_model'
import {useMutation} from 'react-query'
import {deleteAllocateStock} from '../../core/_requests'
import {useQueryClient} from 'react-query'
import {useAuth} from '../../../../../auth'
import {ListLoading} from '../../../../../../../_metronic/common/table/loading/ListLoading'
type Props = {
  id: ID
  stockEvent: StockEvent
}

const StockEventActionsCell: FC<Props> = ({id, stockEvent}) => {
  const {setAction} = useListView()
  const {currentUser} = useAuth()
  const [isDeleteing, setIsDeleteing] = useState<boolean>(false)
  const queryClient = useQueryClient()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setAction({type: EACTION_TYPE.ACTION_EDIT_STOCK_EVENT_ALLOCATION, id: id, payload: stockEvent})
  }

  // const del = useMutation(
  //   () => {
  //     setIsDeleteing(true)
  //     deleteAllocateStock(currentUser, id)
  //   },
  //   {
  //     // 💡 response of the mutation is passed to onSuccess
  //     onSuccess: () => {
  //       // ✅ update detail view directly
  //       // queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
  //     },
  //   }
  // )

  const del = useMutation(
    () => {
      setIsDeleteing(true)
      return deleteAllocateStock(currentUser, id)
    },
    {
      onSuccess: () => {
        setIsDeleteing(false)
        queryClient.invalidateQueries([`${QUERIES.STOCK_EVENTS_LIST}-job-${stockEvent.jobId}`])
        queryClient.invalidateQueries([`${QUERIES.STOCK_EVENTS_LIST}-stock-${stockEvent.stockId}`])
        queryClient.invalidateQueries([`${QUERIES.JOB_DETAILS}-job-${stockEvent.jobId}`])
      },
      onError: () => {
        setIsDeleteing(false)
      },
    }
  )

  return (
    <>
      <a href='#' className='btn btn-light btn-active-light-primary btn-sm' data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end'>
        Actions
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4' data-kt-menu='true'>
        {/* begin::Menu item */}
        {/* <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Edit
          </a>
        </div> */}
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' data-kt-users-table-filter='delete_row' onClick={async () => await del.mutateAsync()}>
            De-Allocate
          </a>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}

      {isDeleteing && (
        <div>
          <ListLoading />
        </div>
      )}
    </>
  )
}

export {StockEventActionsCell}
