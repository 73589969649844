/* eslint-disable react/jsx-no-target-blank */
import {useAuth} from '../../../../../app/modules/auth'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const {currentUser} = useAuth()

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      {/* {currentUser?.groups && (currentUser?.groups.includes('Admin') || currentUser?.groups.includes('Manager')) && <SidebarMenuItem to='/apps/stock-management/stock' icon='/media/icons/duotune/ecommerce/ecm004.svg' title='Stock Items' fontIcon='bi-layers' />}
      {currentUser?.groups && (currentUser?.groups.includes('Admin') || currentUser?.groups.includes('Clerk')) && <SidebarMenuItem to='/apps/job-management/jobs' icon='/media/icons/duotune/ecommerce/ecm006.svg' title='Jobs' fontIcon='bi-layers' />} */}

      <SidebarMenuItem to='/apps/arb-management/arb' icon='/media/icons/duotune/finance/fin010.svg' title='Arb Checker' fontIcon='bi-layers' />
    </>
  )
}

export {SidebarMenuMain}
