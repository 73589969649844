import {ID} from '../../../../../../_metronic/helpers'
import {UserModel} from '../../../../auth'
import {Stock, StockQueryResponse} from '../../stock-list/core/_models'
import {stockEvents, stockEventsForJob, deleteAllocate} from './StockEvents.amplify'

const getEventsForStock = async (currentUser: UserModel | undefined, id: ID): Promise<StockQueryResponse> => {
  if (currentUser) {
    return stockEvents(currentUser, id).then((result) => {
      return result
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

const getStockEventsForJob = async (currentUser: UserModel | undefined, id: ID): Promise<StockQueryResponse> => {
  if (currentUser) {
    return stockEventsForJob(currentUser, id).then((result) => {
      return result
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

const deleteAllocateStock = (currentUser: UserModel | undefined, stockEventId: ID): Promise<Stock | undefined> => {
  if (currentUser && stockEventId) {
    return deleteAllocate(currentUser, stockEventId).then((result) => {
      return result.data
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

export {getEventsForStock, getStockEventsForJob, deleteAllocateStock}
