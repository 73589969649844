import { FC, useState, createContext, useContext } from "react";
import { QueryState, QueryRequestContextProps, initialQueryRequest, WithChildren } from "../../../helpers";

const QueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest);

const QueryRequestProvider: FC<WithChildren> = ({ children }) => {
    const [state, setState] = useState<QueryState>(initialQueryRequest.state);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const updateState = (updates: Partial<QueryState>) => {
        const updatedState = { ...state, ...updates } as QueryState;
        setState(updatedState);
    };

    return <QueryRequestContext.Provider value={{ state, updateState, searchTerm, setSearchTerm }}>{children}</QueryRequestContext.Provider>;
};

const useQueryRequest = () => useContext(QueryRequestContext);
export { QueryRequestProvider, useQueryRequest };
