import {useMemo} from 'react'
import {ListViewProvider} from '../../../../../_metronic/common/table/core/ListViewProvider'
import {KTCard, QUERIES} from '../../../../../_metronic/helpers'
import {Table} from '../../../../../_metronic/common/table/Table'
import {arbColumns} from './table/columns/_columns'
import {QueryRequestProvider} from '../../../../../_metronic/common/table/core/QueryRequestProvider'
import {QueryResponseProvider} from '../../../../../_metronic/common/table/core/QueryResponseProvider'
import {UserModel} from '../../../auth'
import {useInfiniteQuery} from 'react-query'
import {getArb} from './core/_requests'
import {ArbListHeader} from './components/header/ArbListHeader'

const useArb = (query: string, currentUser: UserModel | undefined) => {
  return useInfiniteQuery(
    `${QUERIES.ARB_LIST}-${query}`,
    ({pageParam}) => {
      return getArb(query, currentUser, pageParam)
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.data?.LastEvaluatedKey
      },
      refetchInterval: 10000,
    }
  )
}

const ArbList = () => {
  const columns = useMemo(() => arbColumns, [])
  return (
    <>
      <KTCard>
        <ArbListHeader />
        <Table columns={columns} />
      </KTCard>
    </>
  )
}

const ArbListWrapper = () => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider useDataQuery={useArb}>
        <ListViewProvider>
          <ArbList />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}

export {ArbListWrapper}
