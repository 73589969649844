import {API} from 'aws-amplify'
import {UserModel} from '../../../../auth'

export const list = async (currentUser: UserModel, query: string) => {
  return API.get('app_base', `/arbchecker/arblog?${query}`, {})
}

export const refresh = async (currentUser: UserModel) => {
  return API.post('app_base', `/arbchecker/refresh`, {})
}
