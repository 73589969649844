import React, {FC, useMemo} from 'react'
import {ListViewProvider, useListView} from '../../../../../_metronic/common/table/core/ListViewProvider'
import {KTCard, QUERIES} from '../../../../../_metronic/helpers'
import {Table} from '../../../../../_metronic/common/table/Table'
import {EditModal} from '../../../../../_metronic/common/edit-modal/EditModal'
import {getJobStockColumns, getStockColumns} from './table/columns/_columns'
import {QueryRequestProvider} from '../../../../../_metronic/common/table/core/QueryRequestProvider'
import {QueryResponseProvider} from '../../../../../_metronic/common/table/core/QueryResponseProvider'
import {UserModel, useAuth} from '../../../auth'
import {useInfiniteQuery} from 'react-query'
import {getStock} from './core/_requests'
import {StockListHeader} from './components/header/StockListHeader'
import {StockEditModalFormWrapper} from './stock-edit-modal/StockEditModalFormWrapper'
import {StockActionsModalWrapper} from './stock-actions-modal/StockActionsModalWrapper'
import {Job} from '../../job-management/jobs-list/core/_models'

const useStock = (query: string, currentUser: UserModel | undefined) => {
  return useInfiniteQuery(
    `${QUERIES.STOCK_LIST}-${query}`,
    ({pageParam}) => {
      return getStock(query, currentUser, pageParam)
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.data?.LastEvaluatedKey
      },
      refetchInterval: 10000,
    }
  )
}

const StockList: FC<{jobStockList?: boolean; job?: Job}> = ({jobStockList = false, job}) => {
  const {isSuperAdmin, isAdmin, isStockClerk, isStockManager} = useAuth()
  const {itemIdForUpdate, action} = useListView()
  const columns = useMemo(() => (jobStockList ? getJobStockColumns(job, isSuperAdmin() || isAdmin() || isStockClerk()) : getStockColumns(isSuperAdmin() || isAdmin() || isStockManager())), [job, jobStockList])
  return (
    <>
      <KTCard>
        <StockListHeader jobStockList={jobStockList} />
        <Table columns={columns} />
      </KTCard>
      {itemIdForUpdate !== undefined && (
        <>
          <EditModal title={itemIdForUpdate === null ? 'Add New Stock Item' : 'Edit Stock Item'}>
            <StockEditModalFormWrapper />
          </EditModal>
        </>
      )}

      {action !== undefined && (
        <>
          <StockActionsModalWrapper action={action} />
        </>
      )}
    </>
  )
}

const StockListWrapper: FC<{jobStockList?: boolean; job?: Job}> = ({jobStockList = false, job}) => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider useDataQuery={useStock}>
        <ListViewProvider>
          <StockList jobStockList={jobStockList} job={job} />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}

export {StockListWrapper}
