import React, {useState} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../../../../../../_metronic/common/table/core/ListViewProvider'
import {useAuth} from '../../../../../auth'
import {reportStock} from '../../core/_requests'

export const StockListToolbar = () => {
  const {isSuperAdmin, isAdmin, isStockManager, currentUser} = useAuth()
  const {setItemIdForUpdate} = useListView()
  const [isSubmitting, setSubmitting] = useState(false)

  const [submitted, setSubmitted] = useState(false)

  const openStockModal = () => {
    setItemIdForUpdate(null)
  }

  const stockReport = async () => {
    try {
      setSubmitting(true)
      await reportStock(currentUser)
      setSubmitted(true)
      setTimeout(() => {
        setSubmitted(false)
      }, 5000)
    } catch (ex: any) {
      console.error(ex)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      {(isSuperAdmin() || isAdmin() || isStockManager()) && (
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
          {/* begin::Add user */}
          <button type='button' className='btn btn-sm btn-primary' onClick={stockReport} disabled={isSubmitting || submitted}>
            {!isSubmitting && !submitted && 'Stock Report'}
            {!isSubmitting && submitted && 'Stock Report Requested'}
            {isSubmitting && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Stock Report Generating... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <button type='button' className='btn btn-sm btn-primary ms-3' onClick={openStockModal}>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Add New Stock Item
          </button>
          {/* end::Add user */}
        </div>
      )}
    </>
  )
}
