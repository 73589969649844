import React, {FC, useState} from 'react'
import {Job} from '../core/_models'
import * as Yup from 'yup'
import {useListView} from '../../../../../../_metronic/common/table/core/ListViewProvider'
import {useQueryResponse} from '../../../../../../_metronic/common/table/core/QueryResponseProvider'
import {useAuth} from '../../../../auth'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../../_metronic/helpers'
import {createJob, updateJob} from '../core/_requests'
import {EditFormInput} from '../../../../../../_metronic/common/edit-modal/EditFormInput'
import {ListLoading} from '../../../../../../_metronic/common/table/loading/ListLoading'

type Props = {
  isJobLoading: boolean
  job: Job
  isEdit: boolean
}

const editJobSchema = Yup.object().shape({
  projectName: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Name is required'),
  date: Yup.string().required('Job date is required'),
  grossPrice: Yup.number().positive('Must be a positive number').required('Required'),
})
export const JobEditModalForm: FC<Props> = ({isJobLoading, job, isEdit}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const {currentUser} = useAuth()

  const [error, setError] = useState<string | null>(null)

  const [jobForEdit] = useState<Job>({
    ...job,
    date: new Date().toISOString().substring(0, 10),
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: jobForEdit,
    validationSchema: editJobSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateJob(currentUser, values)
        } else {
          await createJob(currentUser, values)
        }
        setError(null)
        cancel(true)
      } catch (ex: any) {
        console.error(ex)
        setError(ex.response.data.message)
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      {error && <div className='alert alert-danger'>{error}</div>}
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='row'>
          <div className='col-md-12'>
            <EditFormInput label='Project Name' name='projectName' fieldProps={formik.getFieldProps('projectName')} touchedProp={formik.touched.projectName} errorsProp={formik.errors.projectName} isSubmitting={formik.isSubmitting} isLoading={isJobLoading} disabled={isEdit} />

            <EditFormInput label='Project Date' name='date' fieldProps={formik.getFieldProps('date')} touchedProp={formik.touched.date} errorsProp={formik.errors.date} isSubmitting={formik.isSubmitting} isLoading={isJobLoading} isRequired={false} />

            <EditFormInput label='Gross Price' name='grossPrice' fieldProps={formik.getFieldProps('grossPrice')} touchedProp={formik.touched.grossPrice} errorsProp={formik.errors.grossPrice} isSubmitting={formik.isSubmitting} isLoading={isJobLoading} type='number' disabled={isEdit} />
          </div>
          <div className='col-md-12'></div>
        </div>

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button type='reset' onClick={() => cancel()} className='btn btn-light me-3' data-kt-users-modal-action='cancel' disabled={formik.isSubmitting || isJobLoading}>
            Discard
          </button>

          <button type='submit' className='btn btn-primary' data-kt-users-modal-action='submit' disabled={isJobLoading || formik.isSubmitting || !(formik.isValid && formik.dirty) || !formik.touched}>
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isJobLoading) && (
              <span className='indicator-progress'>
                Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isJobLoading) && <ListLoading />}
    </>
  )
}
