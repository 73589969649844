import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {StockListWrapper} from './stock-list/StockList'
import {StockWrapper} from './stock-details/StockDetails'
import {useAuth} from '../../auth'
import {useEffect} from 'react'

const stockBreadcrumbs: Array<PageLink> = [
  {
    title: 'Stock Management',
    path: '/apps/stock-management',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const StockPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='stock'
          element={
            <>
              <PageTitle breadcrumbs={stockBreadcrumbs}>Stock list</PageTitle>
              <StockListWrapper />
            </>
          }
        />
      </Route>
      <Route
        path='stock/:stockId'
        element={
          <>
            <PageTitle breadcrumbs={stockBreadcrumbs}>Stock details</PageTitle>
            <StockWrapper />
          </>
        }
      ></Route>
      <Route index element={<Navigate to='/apps/stock-management/stock' />} />
    </Routes>
  )
}

export default StockPage
