import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {useAuth} from '../../../../../app/modules/auth'

export function MenuInner() {
  const {isSuperAdmin, isAdmin} = useAuth()
  return (
    <>
      {(isSuperAdmin() || isAdmin()) && (
        <MenuInnerWithSub title='Apps' to='/apps' menuPlacement='bottom-start' menuTrigger='click'>
          <MenuItem icon='/media/icons/duotune/general/gen051.svg' to='/apps/user-management/users' title='User management' />
        </MenuInnerWithSub>
      )}
    </>
  )
}
