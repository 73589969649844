import React, {useMemo} from 'react'
import {QueryRequestProvider} from '../../../../../_metronic/common/table/core/QueryRequestProvider'
import {QueryResponseProvider} from '../../../../../_metronic/common/table/core/QueryResponseProvider'
import {ListViewProvider, useListView} from '../../../../../_metronic/common/table/core/ListViewProvider'
import {KTCard, QUERIES} from '../../../../../_metronic/helpers'
import {EditModal} from '../../../../../_metronic/common/edit-modal/EditModal'
import {UserModel} from '../../../auth'
import {useInfiniteQuery} from 'react-query'
import {listJobs} from './core/_requests'
import {jobColumns} from './table/columns/_columns'
import {Table} from '../../../../../_metronic/common/table/Table'
import {JobsListHeader} from './components/header/JobsListHeader'
import {JobEditModalFormWrapper} from './job-edit-modal/JobEditModalFormWrapper'

const useJobs = (query: string, currentUser: UserModel | undefined) => {
  return useInfiniteQuery(
    `${QUERIES.JOBS_LIST}-${query}`,
    ({pageParam}) => {
      return listJobs(query, currentUser, pageParam)
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.data?.LastEvaluatedKey
      },
      refetchInterval: 10000,
    }
  )
}

const JobsList = () => {
  const {itemIdForUpdate} = useListView()
  const columns = useMemo(() => jobColumns, [])
  return (
    <>
      <KTCard>
        <JobsListHeader />
        <Table columns={columns} />
      </KTCard>
      {itemIdForUpdate !== undefined && (
        <>
          <EditModal title={itemIdForUpdate === null ? 'Add Job' : 'Edit Job'}>
            <JobEditModalFormWrapper />
          </EditModal>
        </>
      )}
    </>
  )
}

const JobsListWrapper = () => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider useDataQuery={useJobs}>
        <ListViewProvider>
          <JobsList />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}

export {JobsListWrapper}
