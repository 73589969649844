import { Dispatch, SetStateAction } from "react";

export enum EACTION_TYPE {
    ACTION_ADD_STOCK = "ACTION_ADD_STOCK",
    ACTION_ADJUST_STOCK = "ACTION_ADJUST_STOCK",
    ACTION_EDIT_STOCK_EVENT_ALLOCATION = "ACTION_EDIT_STOCK_EVENT_ALLOCATION",
    ACTION_ALLOCATE_STOCK = "ACTION_ALLOCATE_STOCK",
    ACTION_RESET_PASSWORD = "ACTION_RESET_PASSWORD",
}

export type Action =
    | undefined
    | null
    | {
          type: EACTION_TYPE;
          payload?: any;
          id?: ID;
      };

export type ID = undefined | null | string;

export type PaginationState = {
    page: number;
    items_per_page: 10 | 30 | 50 | 100 | 150;
    links?: Array<{ label: string; active: boolean; url: string | null; page: number | null }>;
};

export type SortState = {
    sort?: string;
    order?: "asc" | "desc";
};

export type FilterState = {
    filter?: unknown;
};

export type SearchState = {
    search?: string;
};

export type Response<T> = {
    data?: T;
    payload?: {
        message?: string;
        errors?: {
            [key: string]: Array<string>;
        };
        pagination?: PaginationState;
    };
};

export type DynamoDbResponse<T> = {
    data?: {
        Items?: T;
        Count?: number;
        ScannedCount?: number;
        LastEvaluatedKey?: {
            pk: string;
            sk: string;
            gsi1pk: string;
            gsi1sk: string;
        };
    };
    payload?: {
        message?: string;
        errors?: {
            [key: string]: Array<string>;
        };
        pagination?: PaginationState;
    };
};

export type DynamoDbInfiniteResponse<T> = {
    pages?: Array<{
        data?: {
            Items?: T;
            Count?: number;
            ScannedCount?: number;
            LastEvaluatedKey?: {
                pk: string;
                sk: string;
                gsi1pk: string;
                gsi1sk: string;
            };
        };
    }>;

    payload?: {
        message?: string;
        errors?: {
            [key: string]: Array<string>;
        };
        pagination?: PaginationState;
    };
};

export type QueryState = PaginationState & SortState & FilterState & SearchState;

export type QueryRequestContextProps = {
    state: QueryState;
    updateState: (updates: Partial<QueryState>) => void;
    companyIdState?: string;
    siteIdState?: string;
    setSiteIdState?: (siteId: string) => void;
    searchTerm: string;
    setSearchTerm: (search: string) => void;
};

export const initialQueryState: QueryState = {
    page: 1,
    items_per_page: 150,
};

export const initialQueryRequest: QueryRequestContextProps = {
    state: initialQueryState,
    updateState: () => {},
    setSiteIdState: () => {},
    searchTerm: "",
    setSearchTerm: () => {},
};

export type QueryResponseContextProps<T> = {
    response?: Response<Array<T>> | undefined;
    refetch: () => void;
    isLoading: boolean;
    query: string;
};

export type DynamoDbQueryResponseContextProps<T> = {
    response?: DynamoDbResponse<Array<T>> | undefined;
    refetch: () => void;
    isLoading: boolean;
    query: string;
};

export type DynamoDbInfiniteQueryResponseContextProps<T> = {
    response?: DynamoDbInfiniteResponse<Array<T>> | undefined;
    refetch: () => void;
    isLoading: boolean;
    query: string;
    fetchNextPage: () => void;
    isFetchingNextPage: boolean;
    hasNextPage: boolean | undefined;
};

export const initialQueryResponse = {
    refetch: () => {},
    isLoading: false,
    query: "",
    fetchNextPage: () => {},
    isFetchingNextPage: false,
    hasNextPage: false,
};

export type ListViewContextProps = {
    selected: Array<ID>;
    onSelect: (selectedId: ID) => void;
    onSelectAll: () => void;
    clearSelected: () => void;
    // NULL => (CREATION MODE) | MODAL IS OPENED
    // NUMBER => (EDIT MODE) | MODAL IS OPENED
    // UNDEFINED => MODAL IS CLOSED
    itemIdForUpdate?: ID;
    setItemIdForUpdate: Dispatch<SetStateAction<ID>>;
    isAllSelected: boolean;
    disabled: boolean;

    action?: Action;
    setAction: Dispatch<SetStateAction<Action>>;
};

export type TypedListViewContextProps<T> = {
    selected: Array<T>;
    onSelect: (selectedId: T) => void;
    onSelectAll: () => void;
    clearSelected: () => void;
    // NULL => (CREATION MODE) | MODAL IS OPENED
    // NUMBER => (EDIT MODE) | MODAL IS OPENED
    // UNDEFINED => MODAL IS CLOSED
    itemForUpdate?: T;
    setItemForUpdate: Dispatch<SetStateAction<T>>;
    isAllSelected: boolean;
    disabled: boolean;
};

export const initialListView: ListViewContextProps = {
    selected: [],
    onSelect: () => {},
    onSelectAll: () => {},
    clearSelected: () => {},
    setItemIdForUpdate: () => {},
    isAllSelected: false,
    disabled: false,

    setAction: () => {},
};

export type SiteListViewContextProps = ListViewContextProps & {
    processBatch?: boolean;
    setProcessBatch: Dispatch<SetStateAction<boolean>>;
};

export const initialSiteListView: SiteListViewContextProps = {
    selected: [],
    onSelect: () => {},
    onSelectAll: () => {},
    clearSelected: () => {},
    setItemIdForUpdate: () => {},
    setProcessBatch: () => {},
    isAllSelected: false,
    disabled: false,

    setAction: () => {},
};
