import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../auth'
import {useParams} from 'react-router-dom'
import {QUERIES, isNotEmpty} from '../../../../../_metronic/helpers'
import {useQuery} from 'react-query'
import {getStockById} from '../stock-list/core/_requests'
import {ListLoading} from '../../../../../_metronic/common/table/loading/ListLoading'
import {StockHeader} from './StockHeader'
import {StockBody} from './StockBody'
import {ListViewProvider, useListView} from '../../../../../_metronic/common/table/core/ListViewProvider'
import {StockActionsModalWrapper} from '../stock-list/stock-actions-modal/StockActionsModalWrapper'
import {EditModal} from '../../../../../_metronic/common/edit-modal/EditModal'
import {StockEditModalFormWrapper} from '../stock-list/stock-edit-modal/StockEditModalFormWrapper'

export const StockDetails = () => {
  const {currentUser} = useAuth()
  const {stockId} = useParams()
  const [enabledQuery, setEnabledQuery] = useState<boolean>(isNotEmpty(stockId))

  useEffect(() => {
    setEnabledQuery(isNotEmpty(stockId))
  }, [stockId])

  const {isLoading, data: stock} = useQuery(
    `${QUERIES.STOCK_DETAILS}-stock-${stockId}`,
    () => {
      return getStockById(stockId, currentUser)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        console.error(err)
      },
    }
  )
  const {itemIdForUpdate, action} = useListView()
  return (
    <>
      {isLoading ? (
        <>
          <ListLoading />
        </>
      ) : (
        <>
          <StockHeader stock={stock} isLoading={isLoading} />
          <StockBody stock={stock} isLoading={isLoading} />

          {action !== undefined && (
            <>
              <StockActionsModalWrapper action={action} />
            </>
          )}

          {itemIdForUpdate !== undefined && (
            <>
              <EditModal title={itemIdForUpdate === null ? 'Add New Stock Item' : 'Edit Stock Item'}>
                <StockEditModalFormWrapper />
              </EditModal>
            </>
          )}
        </>
      )}
    </>
  )
}

export const StockWrapper = () => {
  return (
    <>
      <ListViewProvider>
        <StockDetails />
      </ListViewProvider>
    </>
  )
}
