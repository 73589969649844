import React, {FC} from 'react'
import {Job} from '../jobs-list/core/_models'
import {KTCard, QUERIES} from '../../../../../_metronic/helpers'
import {JobEventsListWrapper} from '../../stock-management/stock-events/StockEventsList'
import {StockListWrapper} from '../../stock-management/stock-list/StockList'
import {issueStockForJob} from '../jobs-list/core/_requests'
import {useAuth} from '../../../auth'
import {useQueryClient} from 'react-query'

type Props = {
  job?: Job
  isLoading: boolean
}

export const JobBody: FC<Props> = ({job, isLoading}) => {
  const {isSuperAdmin, isAdmin, isStockManager, currentUser} = useAuth()
  const [isSubmitting, setSubmitting] = React.useState(false)
  const queryClient = useQueryClient()

  const issueStock = async () => {
    if (job) {
      try {
        setSubmitting(true)
        await issueStockForJob(currentUser, job.id)
        await queryClient.refetchQueries(`${QUERIES.STOCK_EVENTS_LIST}-job-${job.id}`)
        queryClient.refetchQueries(`${QUERIES.JOB_DETAILS}-job-${job.id}`)
      } catch (ex: any) {
        console.error(ex)
        // setError(ex.response.data.message)
      } finally {
        setSubmitting(false)
      }
    } else {
      throw new Error('Job is undefined')
    }
  }

  return (
    <>
      <KTCard className='mb-5'>
        <div>
          <div className=''>
            <div className='flex-grow-1'>
              <>
                <div className='card-header border-0 pt-6'>
                  {/* begin::Card toolbar */}

                  <div className='d-flex align-items-center position-relative my-1'>
                    {/* <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1 position-absolute ms-6' /> */}
                    <div className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>Stock Events</div>
                    {/* <input type='text' data-kt-user-table-filter='search' className='form-control form-control-solid w-250px ps-14' placeholder='Search waybills' /> */}
                  </div>
                  <div className=''>
                    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                      {/* <button type='button' className='btn btn-primary btn-sm' onClick={issueStock}>
                        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                        Issue Stock
                      </button> */}
                      {(isSuperAdmin() || isAdmin() || isStockManager()) && job?.jobStatus === 'ACTIVE' && (job?.allocatedCost && job?.allocatedCost > 0 ? true : false) && (
                        <button onClick={() => issueStock()} className='btn btn-success btn-sm mt-1' disabled={isSubmitting}>
                          {!isSubmitting && 'Issue Stock'}
                          {isSubmitting && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Issuing <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                  {/* end::Card toolbar */}
                </div>

                {/* <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center'>
                      <div className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>Stock Events</div>
                    </div>
                  </div>
                </div> */}

                <div className=''>
                  <div className='flex-equal me-5'>
                    <JobEventsListWrapper job={job} />
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </KTCard>

      {job?.jobStatus === 'ACTIVE' && (
        <KTCard className='mb-5'>
          <div>
            <div className=''>
              <div className='flex-grow-1'>
                <>
                  <div className='card-header border-0 pt-6'>
                    {/* begin::Card toolbar */}

                    <div className='d-flex align-items-center position-relative my-1'>
                      {/* <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1 position-absolute ms-6' /> */}
                      <div className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>Stock</div>
                      {/* <input type='text' data-kt-user-table-filter='search' className='form-control form-control-solid w-250px ps-14' placeholder='Search waybills' /> */}
                    </div>

                    {/* end::Card toolbar */}
                  </div>

                  <div className=''>
                    <div className='flex-equal me-5'>
                      <StockListWrapper jobStockList={true} job={job} />
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        </KTCard>
      )}
    </>
  )
}
