import React from 'react'
import {JobsListSearchComponent} from './JobsListSearchComponent'
import {useListView} from '../../../../../../../_metronic/common/table/core/ListViewProvider'
import {JobListGrouping} from './JobListGrouping'
import {JobListToolbar} from './JobListToolbar'

export const JobsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <JobsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <JobListGrouping /> : <JobListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}
