/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'

import {useQueryRequest} from './QueryRequestProvider'
import {PaginationState, WithChildren, createDynamoDbInfiniteResponseContext, initialQueryResponse, initialQueryState, stringifyRequestQuery} from '../../../helpers'
import {UserModel, useAuth} from '../../../../app/modules/auth'
import {UseInfiniteQueryResult} from 'react-query'

type WithChildrenAndArgs<T> = WithChildren & {
  useDataQuery: (query: string, currentUser: UserModel | undefined, data: T | undefined) => UseInfiniteQueryResult<T>
  data?: T
}

const QueryResponseContext = createDynamoDbInfiniteResponseContext<any>(initialQueryResponse)

const QueryResponseProvider: FC<WithChildrenAndArgs<any>> = ({children, useDataQuery, data}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const {currentUser} = useAuth()

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {isFetching, refetch, data: response, hasNextPage, fetchNextPage, isFetchingNextPage} = useDataQuery(query, currentUser, data)

  return <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query, fetchNextPage, isFetchingNextPage, hasNextPage}}>{children}</QueryResponseContext.Provider>
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return {
      pages: [],
    }
  }
  return response || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

const useQueryResponseFetchNextPage = (): (() => void) => {
  const {fetchNextPage} = useQueryResponse()
  return fetchNextPage
}

const useQueryResponseHasMoreData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return false
  }

  if (response.pages) {
    const pageCount = response.pages.length
    return response.pages[pageCount - 1].data?.LastEvaluatedKey ? true : false
  } else {
    return false
  }
}

export {QueryResponseProvider, useQueryResponse, useQueryResponseData, useQueryResponsePagination, useQueryResponseLoading, useQueryResponseFetchNextPage, useQueryResponseHasMoreData}
