import {UserModel} from '../../../../auth'
import {list, refresh} from './ArbHelpers.amplify'
import {ArbQueryResponse} from './_models'

export const getArb = async (query: string, currentUser?: UserModel, pageParam?: any): Promise<ArbQueryResponse> => {
  if (currentUser) {
    if (pageParam) {
      query += `&last_evaluated_key_pk=${encodeURIComponent(pageParam.pk)}&last_evaluated_key_sk=${encodeURIComponent(pageParam.sk)}&last_evaluated_key_gsi1pk=${encodeURIComponent(pageParam.gsi1pk)}&last_evaluated_key_gsi1sk=${encodeURIComponent(pageParam.gsi1sk)}`
    }

    return list(currentUser, query).then((result) => {
      return result
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

export const refreshArb = async (currentUser?: UserModel): Promise<ArbQueryResponse> => {
  if (currentUser) {
    return refresh(currentUser).then((result) => {
      return result
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}
