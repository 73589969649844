import {FC} from 'react'
import {ID} from '../../../../../../../_metronic/helpers'
import {Link} from 'react-router-dom'

type Props = {
  name: string
  id: ID
}

export const StockLinkCell: FC<Props> = ({name, id}) => {
  return (
    <>
      <Link to={`/apps/stock-management/stock/${id}`}>{name}</Link>
    </>
  )
}
