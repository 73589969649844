import {Column} from 'react-table'
import {Stock} from '../../core/_models'
import {CustomHeader} from '../../../../../../../_metronic/common/table/columns/CustomHeader'
import {StockLinkCell} from './StockLinkCell'
import {StockActionsCell} from './StockActionsCell'
import {StockActionsForJobCell} from './StockActionsForJobCell'
import {Job} from '../../../../job-management/jobs-list/core/_models'
import {StockMinumumQuantityCell} from './StockMinumumQuantityCell'
import {StockAvailableQuantityCell} from './StockAvailableQuantityCell'

const getColums = (actions: any) => {
  const stockColumns: ReadonlyArray<Column<Stock>> = [
    {
      Header: (props) => <CustomHeader tableProps={props} title='Name' className='min-w-125px' />,
      id: 'name',
      Cell: ({...props}) => <StockLinkCell id={props.data[props.row.index].id} name={props.data[props.row.index].name ?? ''} />,
    },

    {
      Header: (props) => <CustomHeader tableProps={props} title='Description' className='min-w-125px' />,
      accessor: 'description',
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title='Bin' className='min-w-125px' />,
      accessor: 'bin',
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title='Quantity' className='min-w-125px' />,
      accessor: 'quantity',
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title='Allocated' className='min-w-125px' />,
      accessor: 'allocated',
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title='Available' className='min-w-125px' />,
      id: 'available',
      Cell: ({...props}) => <StockAvailableQuantityCell id={props.data[props.row.index].id} stock={props.data[props.row.index]} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title='Min Quantity' className='min-w-125px' />,
      id: 'minimumQuantity',
      accessor: 'minimumQuantity',
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title='Unit Price' className='min-w-125px' />,
      accessor: 'unitPrice',
      Cell: (props) => <div> R {props.value?.toFixed(2)} </div>,
    },
  ]

  if (actions) {
    const stockColumnsFinal: ReadonlyArray<Column<Stock>> = [
      ...stockColumns,
      {
        Header: (props) => <CustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />,
        id: 'actions',
        Cell: actions,
      },
    ]
    return stockColumnsFinal
  }
  return stockColumns
}

const getStockColumns = (showActions: boolean = false) => {
  if (!showActions) {
    return getColums(null)
  }

  const actions = ({...props}) => <StockActionsCell id={props.data[props.row.index].id} stock={props.data[props.row.index]} />
  return getColums(actions)
}

const getJobStockColumns = (job: Job | undefined, showActions: boolean = false) => {
  if (job) {
    if (!showActions) {
      return getColums(null)
    }
    const actions = ({...props}) => <StockActionsForJobCell id={props.data[props.row.index].id} stock={props.data[props.row.index]} job={job} />
    return getColums(actions)
  }
  return []
}
export {getStockColumns, getJobStockColumns}
