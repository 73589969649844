import {FC, useEffect, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row, Column} from 'react-table'
import {useQueryResponseData, useQueryResponseFetchNextPage, useQueryResponseHasMoreData, useQueryResponseLoading} from './core/QueryResponseProvider'
import {KTCardBody} from '../../helpers'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'

type TableArgs = {
  columns: ReadonlyArray<Column<any>>
}

const Table: FC<TableArgs> = ({columns}) => {
  const {pages} = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const fetchNextPage = useQueryResponseFetchNextPage()
  const hasMorePages = useQueryResponseHasMoreData()
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (isLoading === false) {
      setIsLoaded(true)
    }
  }, [isLoaded])

  const data: any[] = useMemo(() => {
    return pages ? pages.flatMap((page) => page.data?.Items || []) : []
  }, [pages])

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table id='kt_table_users' className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer' {...getTableProps()}>
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<any>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<any>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>No matching records found</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {hasMorePages && (
        <>
          <button onClick={() => fetchNextPage()} className='btn btn-primary btn-sm mt-5' disabled={isLoading || !hasMorePages}>
            {!isLoading && 'Load More'}
            {isLoading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Loading... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </>
      )}

      {!hasMorePages && (
        <>
          <span>
            {!isLoading && <span style={{display: 'block'}}>&nbsp;</span>}
            {isLoading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                &nbsp; <span className='text-primary spinner-border spinner-border-sm '></span>
              </span>
            )}
          </span>
        </>
      )}
    </KTCardBody>
  )
}

export {Table}
