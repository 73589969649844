import {Column} from 'react-table'
import {Job} from '../../core/_models'
import {CustomHeader} from '../../../../../../../_metronic/common/table/columns/CustomHeader'
import {JobLinkCell} from './JobLinkCell'

const jobColumns: ReadonlyArray<Column<Job>> = [
  {
    Header: (props) => <CustomHeader tableProps={props} title='Project Name' className='min-w-125px' />,
    id: 'projectName',
    Cell: ({...props}) => <JobLinkCell id={props.data[props.row.index].id} projectName={props.data[props.row.index].projectName ?? ''} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Date' className='min-w-125px' />,
    accessor: 'date',
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Job Status' className='min-w-125px' />,
    accessor: 'jobStatus',
    Cell: (props) => <div> {props.value === 'COMPLETE' ? <span className='text-success'>{props.value}</span> : <span className='text-warning'>{props.value}</span>} </div>,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Gross Price' className='min-w-125px' />,
    accessor: 'grossPrice',
    Cell: (props) => <div> R {props.value?.toFixed(2)} </div>,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Allocated Cost' className='min-w-125px' />,
    accessor: 'allocatedCost',
    Cell: (props) => <div> R {props.value?.toFixed(2)} </div>,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Issued Cost' className='min-w-125px' />,
    accessor: 'issuedCost',
    Cell: (props) => <div> R {props.value?.toFixed(2)} </div>,
  },
]

export {jobColumns}
