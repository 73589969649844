import {ID} from '../../../../../../_metronic/helpers'
import {UserModel} from '../../../../auth'
import {add, allocate, create, get, list, report, update} from './StockHelpers.amplify'
import {Stock, StockQueryResponse} from './_models'

const getStock = async (query: string, currentUser?: UserModel, pageParam?: any): Promise<StockQueryResponse> => {
  if (currentUser) {
    if (pageParam) {
      query += `&last_evaluated_key_pk=${encodeURIComponent(pageParam.pk)}&last_evaluated_key_sk=${encodeURIComponent(pageParam.sk)}&last_evaluated_key_gsi1pk=${encodeURIComponent(pageParam.gsi1pk)}&last_evaluated_key_gsi1sk=${encodeURIComponent(pageParam.gsi1sk)}`
    }

    return list(currentUser, query).then((result) => {
      return result
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

const getStockById = (id: ID, currentUser: UserModel | undefined): Promise<Stock | undefined> => {
  if (currentUser && id) {
    return get(id, currentUser).then((result) => {
      return result.data
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

const createStock = (currentUser: UserModel | undefined, stock: Stock): Promise<Stock | undefined> => {
  if (currentUser) {
    return create(currentUser, stock).then((result) => {
      return result.data
    })
    // .catch((error) => {
    //   console.log({error})
    //   return Promise.reject(error)
    // })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

const updateStock = (currentUser: UserModel | undefined, stock: Stock): Promise<Stock | undefined> => {
  if (currentUser) {
    return update(currentUser, stock).then((result) => {
      return result.data
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

const addStock = (currentUser: UserModel | undefined, id: ID, quantityToAdd: number, unitPrice: number): Promise<Stock | undefined> => {
  if (currentUser && id) {
    return add(currentUser, id, quantityToAdd, unitPrice).then((result) => {
      return result.data
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

const allocateStock = (currentUser: UserModel | undefined, jobId: ID, stockId: ID, quantityToAllocate: number): Promise<Stock | undefined> => {
  if (currentUser && jobId && stockId) {
    return allocate(currentUser, jobId, stockId, quantityToAllocate).then((result) => {
      return result.data
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

const reportStock = (currentUser: UserModel | undefined): Promise<Stock | undefined> => {
  if (currentUser) {
    return report(currentUser).then((result) => {
      return result.data
    })
  } else {
    return Promise.reject(new Error('Current user is null'))
  }
}

export {getStock, getStockById, createStock, updateStock, addStock, allocateStock, reportStock}
