import {FC, useMemo} from 'react'
import {KTCard, QUERIES} from '../../../../../_metronic/helpers'
import {Table} from '../../../../../_metronic/common/table/Table'
import {} from '../../../../../_metronic/common/edit-modal/EditModal'
import {QueryRequestProvider} from '../../../../../_metronic/common/table/core/QueryRequestProvider'
import {QueryResponseProvider} from '../../../../../_metronic/common/table/core/QueryResponseProvider'
import {UserModel} from '../../../auth'
import {useInfiniteQuery} from 'react-query'
import {getEventsForStock, getStockEventsForJob} from './core/_requests'
import {Stock} from '../stock-list/core/_models'
import {getStockEventColumns, getStockEventColumnsForJob, stockEventColumns} from './table/columns/_columns'
import {Job} from '../../job-management/jobs-list/core/_models'

const useStockEvents = (query: string, currentUser: UserModel | undefined, data: Stock) => {
  return useInfiniteQuery(
    `${QUERIES.STOCK_EVENTS_LIST}-stock-${data.id}`,
    ({pageParam}) => {
      return getEventsForStock(currentUser, data.id)
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.data?.LastEvaluatedKey
      },
      refetchInterval: 10000,
    }
  )
}

const useJobEvents = (query: string, currentUser: UserModel | undefined, data: Job) => {
  return useInfiniteQuery(
    `${QUERIES.STOCK_EVENTS_LIST}-job-${data.id}`,
    ({pageParam}) => {
      return getStockEventsForJob(currentUser, data.id)
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.data?.LastEvaluatedKey
      },
      refetchInterval: 10000,
    }
  )
}

const StockEventsList = () => {
  const columns = useMemo(() => getStockEventColumns(), [])
  return (
    <>
      <KTCard>
        <Table columns={columns} />
      </KTCard>
    </>
  )
}

const JobEventsList = () => {
  const columns = useMemo(() => getStockEventColumnsForJob(), [])
  return (
    <>
      <KTCard>
        <Table columns={columns} />
      </KTCard>
    </>
  )
}

type Props = {
  stock?: Stock
  job?: Job
}

const StockEventsListWrapper: FC<Props> = ({stock}) => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider useDataQuery={useStockEvents} data={stock}>
        <StockEventsList />
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}

const JobEventsListWrapper: FC<Props> = ({job}) => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider useDataQuery={useJobEvents} data={job}>
        <JobEventsList />
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}

export {StockEventsListWrapper, JobEventsListWrapper}
